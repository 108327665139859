import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { openPopupWidget } from "react-calendly";

import Layout from "../../components/Layout";
import ContentSection from "../../components/ContentSection";
import Blurbs from "../../components/Blurbs";

// import Hero from "../../components/Hero";
import CTA from "../../components/CTA";

import { Section, Hero } from "../../components/bulma";

import SEO from "../../components/SEO/SEO";

const url = "https://calendly.com/fastlabs/free-consultation";

const onClick = () => openPopupWidget({ url });

export default function BlogIndexPage() {
  const data = useStaticQuery(CASE_STUDIES_QUERY);
  const { edges } = data.allMarkdownRemark;

  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(
      edges.map(({ node }) => ({
        title: node.frontmatter.heading,
        text: node.frontmatter.subheading,
        image: node.frontmatter.image,
        link: node.fields.slug,
      }))
    );
  }, [edges]);

  return (
    <Layout>
      <SEO
        title="Case Studies"
        description="Learn about Payment.Ninja's cutting-edge platform features."
        // image={image.childImageSharp.sizes.src}
        pathname="/news"
        article
      />
      <br />
      <Hero>
        <div className="columns is-vcentered">
          <div className="column is-6-desktop p-6">
            <h1 className="title is-1 is-hidden-mobile  has-text-weight-bold is-spaced">
              Case Studies
            </h1>
            <h1 className="title is-hidden-desktop is-size-2-mobile has-text-weight-bold is-spaced">
              <br />
              Case Studies
            </h1>
            <h2 className="subtitle  is-size-4-mobile is-size-4">
              We have built a numerous mobile apps for different kind of
              businesses.
            </h2>

            <div className="buttons">
              <p className="control">
                <button
                  className="button is-primary is-medium is-rounded"
                  onClick={onClick}
                >
                  <strong>TALK TO EXPERT</strong>
                </button>
              </p>

              <p className="control">
                <Link
                  to="/pricing"
                  className="button is-text is-medium is-rounded"
                >
                  View pricing
                </Link>
              </p>
              <p>Book a free, no-obligation consultation now!</p>
            </div>
          </div>
          <div className="column is-6">
            {/* <PreviewCompatibleImage imageInfo={image} /> */}
          </div>
        </div>
        <Blurbs
          box
          items={[
            {
              title: "Various industries",
              text:
                "From banking to dating, we have built apps for many different industries.",
            },
            {
              title: "Multiple platforms",
              text:
                "All our apps are designed to work both on Apple iPhone and Android smartphones. ",
            },
            {
              title: "Different businesses",
              text:
                "We worked with startups, small businesses and corporate innovation teams",
            },
          ]}
        />
      </Hero>

      <Section>
        <ContentSection items={sections} box />
      </Section>

      <Section>
        <CTA />
      </Section>
    </Layout>
  );
}

const CASE_STUDIES_QUERY = graphql`
  query CaseStudies {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "case-study-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            heading
            subheading
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
